var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',[_c('div',{staticClass:"middel_auto_with"},[_c('v-card',{attrs:{"outlined":"","loading":_vm.loading}},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"login_top_sec"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/images/login-logo.svg"),"alt":"Exsport"}})]),_c('h2',[_vm._v("Sign Up")]),_c('v-card-subtitle',[_vm._v("Let's get you all set up so you can verify your personal account and begin setting up your profile.")])],1),_c('v-card-text',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signUp)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","dense":"","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","dense":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Channel Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Channel Name","outlined":"","dense":""},model:{value:(_vm.channelName),callback:function ($$v) {_vm.channelName=$$v},expression:"channelName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phoneNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":10,"error-messages":errors,"label":"Phone Number","outlined":"","dense":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('v-radio-group',{attrs:{"row":"","mandatory":""}},[_c('v-radio',{attrs:{"label":"Individual","value":"2"},on:{"change":function($event){return _vm.onChange(_vm.type=1)}}}),_c('v-radio',{attrs:{"label":"School","value":"1"},on:{"change":function($event){return _vm.onChange(_vm.type=2)}}})],1),_c('ValidationProvider',{attrs:{"name":"outlined"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.show_div)?_c('v-select',{attrs:{"items":_vm.classnames,"label":"Class Name","dense":"","outlined":"","error-messages":errors},model:{value:(_vm.classname),callback:function ($$v) {_vm.classname=$$v},expression:"classname"}}):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"school"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"School name","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.schoolName),callback:function ($$v) {_vm.schoolName=$$v},expression:"schoolName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Location","error-messages":errors},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date of Birth","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{staticClass:"mt-4",attrs:{"error-messages":errors,"allowed-dates":function (date) { return date <= new Date().toISOString().substr(0, 10); }},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)]}}],null,true)}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Password","outlined":"","dense":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","error-messages":errors,"label":"Confirm Password","outlined":"","dense":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"bio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","auto-grow":"","row-height":"20","name":"input-7-4","label":"Write few words about yourself","hide-details":"true","error-messages":errors,"value":""},model:{value:(_vm.bio),callback:function ($$v) {_vm.bio=$$v},expression:"bio"}})]}}],null,true)}),_c('div',{staticClass:"checkbox_section"},[_c('v-checkbox',{attrs:{"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" By signing up, your agree to our "),_c('a',_vm._g({attrs:{"target":"_blank","href":"/terms"},on:{"click":function($event){$event.stopPropagation();}}},_vm.on),[_vm._v("Terms & Conditions")])])]},proxy:true}],null,true),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}})],1),_c('div',{staticClass:"mt-3 d-flex justify-space-between"},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"block","dark":""},on:{"click":function($event){return _vm.$router.push('/')}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" CANCEL ")]),_c('v-btn',{staticClass:"primary",attrs:{"type":"submit","loading":_vm.loading,"depressed":""}},[_vm._v("Sign up")])],1),_c('div',{staticClass:"dont_have_on_account"},[_c('h4',[_vm._v("Don't have an account? "),_c('router-link',{attrs:{"to":"signin"}},[_vm._v("LOGIN")])],1)])],1)]}}])})],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }