<template>
  <v-container fluid class="fill-height">
    
    <v-row>
     <div class="middel_auto_with">
        <v-card class="" outlined :loading="loading">
          <v-row class="justify-space-between">
            <v-col cols="12" sm="12" md="12">
                       <div class="login_top_sec">
          <router-link to="/">   <img src="../../assets/images/login-logo.svg" alt="Exsport"/></router-link>
             <h2>Sign Up</h2>
            <v-card-subtitle>Let's get you all set up so you can verify your personal account 
                 and begin setting up your profile.</v-card-subtitle>
            </div>
              <v-card-text>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <form
                    @submit.prevent="handleSubmit(signUp)"
                    @reset.prevent="reset"
                  >
               <ValidationProvider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <v-text-field
                        v-model="name"
                        :error-messages="errors"
                        label="Name"
                       dense
                        outlined
                        
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="Email"
                       dense
                        outlined
                        
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Channel Name"
                      rules="required|min:3"
                    >
                      <v-text-field
                        v-model="channelName"
                        :error-messages="errors"
                        label="Channel Name"
                        outlined
                        dense
                        
                      ></v-text-field>
                    </ValidationProvider>

                           <ValidationProvider
        v-slot="{ errors }"
        name="phoneNumber"
          rules="required"
      >
       <v-text-field
          v-model="phoneNumber"
          :counter="10"
          :error-messages="errors"
          label="Phone Number"
           outlined
           dense
          
        ></v-text-field>
      </ValidationProvider>
        <v-radio-group
      row
      mandatory
    >
     <v-radio
        label="Individual"
        value=2
         @change="onChange(type=1)"
      ></v-radio>
      <v-radio
        label="School"
        value=1
        @change="onChange(type=2)"></v-radio>
     
    </v-radio-group>

  <ValidationProvider  
                v-slot="{ errors }"
                name="outlined"
              >

     <v-select
      v-if="show_div"
          :items="classnames"
          v-model="classname"
          label="Class Name"
          dense
          outlined
          :error-messages="errors"
        ></v-select>
         </ValidationProvider>

        <ValidationProvider
         v-slot="{ errors }"
        name="school"
          >
          <v-text-field
            v-model="schoolName"
            label="School name"
             :error-messages="errors"
            outlined
            dense
          ></v-text-field>
        </ValidationProvider>
      <ValidationProvider  v-slot="{ errors }"
                      name="location"
                  rules="required">
       <v-text-field
            outlined
            dense
            label="Location"
    v-model="location" 
            :error-messages="errors"
          > </v-text-field>
      </ValidationProvider>


<ValidationProvider
                          v-slot="{ errors }"
                          name="date"
                          rules="required"
                        >
          <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        
      >
       
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Select Date of Birth"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
            dense
            :error-messages="errors"

          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="menu2 = false"
          :error-messages="errors"
         :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"

        class="mt-4"
        ></v-date-picker>
      </v-menu>
            </ValidationProvider>

                    <v-row>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Password"
                          rules="required|password:@confirm"
                        >
                          <v-text-field
                            v-model="password"
                            type="password"
                            :error-messages="errors"
                            label="Password"
                            outlined
                            dense
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="confirm"
                          rules="required"
                        >
                          <v-text-field
                            type="password"
                            v-model="confirmPassword"
                            :error-messages="errors"
                            label="Confirm Password"
                            outlined
                            dense
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                     <ValidationProvider
                           v-slot="{ errors }"
                      name="bio"
                  rules="required" 
                        >
                    <v-textarea
          v-model="bio"
          outlined
          auto-grow
          row-height="20"
          name="input-7-4"
          label="Write few words about yourself" hide-details=true
          :error-messages="errors"
          value=""
        ></v-textarea>
         </ValidationProvider>
                            <div class="checkbox_section">
                      <v-checkbox v-model="agree" required>
      <template v-slot:label>
        <div>
         By signing up, your agree to our <a
                target="_blank"
                href="/terms"
                @click.stop
                v-on="on"
              >Terms & Conditions</a> <!-- and <a
                target="_blank"
                href="/privacy"
                @click.stop
                v-on="on"
              >Privacy Policy </a> -->
        </div>
      </template>
    </v-checkbox>
                    </div>
                    <div class="mt-3 d-flex justify-space-between">
                           <v-btn
          color="block"
          dark
          v-bind="attrs"
          v-on="on"
          @click="$router.push('/')"
        >
         CANCEL
        </v-btn>
                      <v-btn
                        type="submit"
                        class="primary"
                        :loading="loading"
                        depressed
                        >Sign up</v-btn
                      >
                    </div>

            
     <div class="dont_have_on_account">
                    <h4>Don't have an account? <router-link 
                        to="signin">LOGIN</router-link></h4>
                  </div>
                  </form>
                </ValidationObserver>
              </v-card-text>
            </v-col>
           
          </v-row>
        </v-card>
      </div>
    </v-row>
  </v-container>
</template>




<script>

export default {
    
  name: 'SignUp',
  data: () => ({
    email: '',
    name: '',
    phoneNumber: '',
    channelName: '',
    schoolName: '',
agree: {
     checked: value => value === true
    },    password: '',
    confirmPassword: '',
    loading: false,
    attrs:'',
    location:'',
    on:'',
    bio:'',
    show_div:true,
id:'',
     classname:'',

     classnames: ['First Standard', 'Second Standard', 'Third Standard', 'Fourth Standard','Fifth Standard','Sixth Standard','Seventh Standard','Eight Standard','Nineth Standard','Tenth Standard'],
     //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
     menu: false,
    
      date : '',
      modal: false,
      menu2: false,
  }),

  methods: {
    async signUp() 
    {   
   
      this.loading = true
    await this.$store
        .dispatch('signUp', {
          email: this.email,
          phone: this.phoneNumber,
          channelName: this.channelName,
          className: this.classname,
          schoolName: this.schoolName,
          password: this.password,
          name: this.name,
          location: this.location,
          dateofBirth: this.date,
          biograph: this.bio,
           agree: true,

        }).then(response => {
          if(response.statusCode == 401){
      this.loading = false 
      this.$toasted.show("Email already exists", {
                theme: "bubble",
                position: "bottom-right",
                type: "error",
                duration: 5000
              });
          }else{
      this.loading = false 
      this.$toasted.show("Registration completed login and proceed", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000
              });
      this.$router.push('/signin')

          }

        })
        .catch((err) => {
          this.loading = false
          const errors = err.response.data.error

          this.$refs.form.setErrors({
            'Email': errors.find((error) => {
              return error.field === 'email'
            })
              ? ['This email is already taken']
              : null,
            'Channel Name': errors.find((error) => {
              return error.field === 'channelName'
            })
              ? ['This channel name is already taken']
              : null,
               'phoneNumber': errors.find((error) => {
              return error.field === 'phoneNumber'
            })
              ? ['This channel name is already taken']
              : null
             
          })
        })
    },

   onChange(event) {
if(event==2){
  this.show_div =false
  } else{
  this.show_div =true
}},

         getAddressData: function (addressData, placeResultData,id) {          
        console.log(addressData)
            this.location = placeResultData.formatted_address;
            this.loclat = addressData.latitude
            this.loclng = addressData.longitude
              this.id = id
            this.loc_lat_lng = this.loclat+','+this.loclng
            localStorage.setItem('loc_lat_lng', this.loclat+','+this.loclng)
            localStorage.setItem('auto_lat', this.loclat)
            localStorage.setItem('auto_lng', this.loclng)
      },



  }
}
</script>

<style>
.v-application--is-ltr .v-text-field--outlined fieldset {
    background: #ffffff; border:1px solid #ebebeb
}
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: rgb(255 255 255 / 6%);
    border: 1px solid #ebebeb;
}
</style>
